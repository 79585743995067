// extracted by mini-css-extract-plugin
export var about = "home-module--about--+19Em";
export var aboutContent = "home-module--aboutContent--igOdI";
export var background = "home-module--background--grV-9";
export var external = "home-module--external--UluOT";
export var externalContent = "home-module--externalContent--3EkO9";
export var hero = "home-module--hero--oo8rD";
export var heroContent = "home-module--heroContent--q4JcI";
export var page = "home-module--page--m1Y8L";
export var services = "home-module--services--W0PrT";
export var servicesContent = "home-module--servicesContent--GGU1N";